
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import { PRICE_SHOWN } from '@/modules/rates/constants';
import { OtelRateDayPopupLoading } from '@/modules/open-telemetry/decorators';
import type Day from '@/modules/common/types/day.type';
import type UserService from '@/modules/user/user.service';

import DayPopup from './day-popup/index.vue';
import {
    RateTrendsService, TrendsWithRatesDocument, TableOptions, ScanProps,
    TrendsPopup,
} from '../types';
import { ColumnKey } from '../constants';

@OtelRateDayPopupLoading(KEY.HotelCheapestTrendsService)
@Component({ components: { DayPopup } })
export default class HotelCheapestDayPopup extends Vue implements TrendsPopup {
    /** This component is only for cheapest channel hotel rates page popup */

    @inject(KEY.HotelCheapestTrendsService) private rateTrendsService!: RateTrendsService & TrendsWithRatesDocument;
    @inject(KEY.UserService) private userService!: UserService;

    mounted() {
        this.rateTrendsService.init(this.day);
    }

    get day() {
        return parseInt(this.$route.params.day, 10) as Day;
    }

    get tableColumns() {
        return {
            [ColumnKey.PRICE_TYPE]: this.rateTrendsService.filters?.priceType === PRICE_TYPE.LOWEST
                ? {
                    label: 'titles.type',
                    width: '6%',
                }
                : undefined,
            [ColumnKey.NAME]: {
                label: 'titles.name',
                width: '34%',
            },
            [ColumnKey.RANK]: {
                label: 'titles.rank',
                width: '5%',
            },
            [ColumnKey.BASIC]: {
                label: '',
                width: '2%',
            },
            [ColumnKey.PRICE]: {
                label: 'titles.price',
                width: '12%',
            },
            [ColumnKey.ROOM_NAME]: {
                label: 'titles.roomName',
                width: '21%',
            },
            [ColumnKey.PROVIDERS]: {
                label: 'titles.providers',
                width: '20%',
            },
        };
    }

    get tableOptions() {
        return {
            isLinkShown: true,
            isLOSRestrictionShown: true,
            isNetTotalCalcShown: true,
        } as TableOptions;
    }

    get compset() {
        return this.rateTrendsService.compset;
    }

    get scanProps() {
        return {
            lastScan: null,
            data: null,
            settings: null,
            compsetId: '',
            day: this.day,
            disabled: true,
            showScanDate: true,
        } as ScanProps;
    }

    get isDocumentLoading() {
        return this.rateTrendsService.ratesLoading.isLoading();
    }

    get isSuperadmin() {
        return this.userService.isSuperadmin;
    }

    handleDayChange() {
        this.$nextTick(() => this.rateTrendsService.update(this.day));
    }

    handlePriceShownChange(e: PRICE_SHOWN) {
        this.rateTrendsService.localPriceShown = e;
    }
}
